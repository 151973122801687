<template>
    <div class="re-comments-box">
        <v-overlay class="navigation-more-overlay" :value="sendpost" @click="sendpost = false"></v-overlay>
        <template>
            <div class="text-center">
            <v-btn class="post-send-mobile post-send-mobile-ad" color="blue" @click="sendpost = !sendpost" style="position: fixed; z-index: 999; bottom: 80px; border-radius: 50%; height: 65px; width: 65px; background: #0d3d62; left: 15px; color: rgb(255, 255, 255); font-size: 14px; font-weight: 600; box-shadow: unset !important;min-width: unset !important;">
                <i class="v-icon mdi mdi-comment-text-outline" style="font-size: 30px;"></i>
            </v-btn>
                <v-bottom-sheet v-model="sendpost">
                    <v-sheet class="text-center">
                        <div class="re-comments-avatar">
                            <img :src="checkUserAvatar">
                        </div>
                        <div :class="'re-comments-postsend ' + (sendPostLoading ? 'loading-active' : '')">
                            <!-- <div class="close-postsend" @click="sendpost = false"><i class="uil uil-angle-down"></i></div> -->
                            <div class="tgju-widget tgju-widget-boxed light post">
                                <div class="post-input-box" ref="post-input-box">
                                    <form @submit.prevent="">
                                        <Mentionable :keys="['@']" :items="mentionItems" offset="6" :insert-space="false" @open="mentionOnOpen" ref="mention-post" @search="updateMention">
                                            <Editable v-model="$parent.newPost.content" class="autosize dashboard-post-textarea textarea-post twemojiTextarea placeholderTextareaPost" ref="textarea-post" style="height: 200px;" placeholder="متن خود را بنویسید ..."></Editable>
                                            <template #no-result>
                                                <div class="dim" v-if="searchMentionResult">
                                                    در حال جستجو...
                                                </div>
                                                <div class="dim" v-else>
                                                    موردی یافت نشد.
                                                </div>
                                            </template>
                                            <template #item-@="{ item }">
                                                <div class="item">
                                                    {{ item.value }}
                                                    <span class="dim">
                                                        ({{ item.username }})
                                                    </span>
                                                </div>
                                            </template>
                                        </Mentionable>
                                        <!-- <div class="dashboard-post-preview-image" v-if="technical_image_preview">
                                                <div class="preview_image-delete" @click="removeTechnical"><i class="uil uil-times-circle" aria-hidden="true"></i></div>
                                                {{ technical_image_preview.image }}
                                        </div> -->

                                        <div class="dashboard-post-preview-image" v-if="technical_image_preview">
                                            <div class="post-technical">
                                                <div class="post-technical-img" :style="technicalImageFinder(technical_image_preview)">
                                                    <div class="post-technical-img-tags-row">
                                                        <div class="post-technical-img-tags-date"><span>{{ toRelative(technical_image_preview.created_at) }}</span></div>
                                                        <div class="post-technical-img-tags-flex"></div>
                                                    </div>
                                                </div>

                                                <div class="post-technical-content">
                                                    <h2>{{ technical_image_preview.title }}</h2>
                                                    <p>{{ technical_image_preview.content }}</p>
                                                </div>
                                                <div class="preview_image-delete" @click="removeTechnical"><i class="uil uil-times-circle" aria-hidden="true"></i></div>
                                            </div>
                                        </div>

                                        <div class="dashboard-post-btn dashboard-post-btn-flex">
                                            <div class="tgju-btn tgju-btn-primary tgju-btn-send-post" @click="sendPost()">
                                                <span><template>{{ $parent.newPost.id ? 'ویرایش نظر' : 'ارسال نظر' }}</template></span>
                                            </div>
                                            <div class="m-right tgju-btn tgju-btn-light tgju-btn-cancel-post" @click="resetNewPost()">
                                                <span class="cancel">انصراف</span>
                                            </div>
                                            <div class="dashboard-post-btn-left tgju-btn-emoji-post">
                                                <label v-tooltip="'افزودن شکلک'">
                                                    <button class="sendpost-icon" @click="toggleEmoji"><i class="uil uil-smile" style="font-size: 30px;line-height: 0;padding: 4px 0;display: block;"></i></button>
                                                </label>
                                                <div class="wrapper" v-show="showEmojiDialog">
                                                    <VEmojiPicker 
                                                    @select="onSelectEmoji"
                                                    :showSearch="true"
                                                    :customCategories="customCategories"
                                                    :emojiWithBorder="false"
                                                    :i18n="i18n"
                                                    />
                                                    <div class="EmojiPicker-wrapper-bg" @click="toggleEmoji"></div>
                                                </div>
                                            </div>
                                            <div class="m-right tgju-btn tgju-btn-light tgju-btn-cancel-post" style="padding: 0px 14px 6px !important;" @click="toggleTechnicalsList" >
                                                <i class="uil uil-chart" style="font-size: 24px;"></i>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                        <div class="technical-modal" v-show="showTechnicalsList">
                            <div class="technical-modal-bg" @click="toggleTechnicalsList"></div>
                            <div class="technical-modal-box">
                                <div class="technical-modal-title">
                                    <h3><i class="uil uil-chart"></i> تحلیـل‌ها</h3>
                                    <span><i class="uil uil-filter" @click="filter = !filter"></i></span>
                                </div>

                                <div v-if="filter" class="technical-modal-box-header" @click.self="filter = !filter">
                                    <div class="technical-modal-filter" @click="(filter = true)">

                                        <v-select class="profile-mobile-view-select" v-model="selected" return-object item-text="value" item-value="key" outlined :items="[{key: 'newest', value: 'جدیدترین'},{key: 'oldest', value: 'قدیمی ترین'}]" @input="selectFilter">
                                            <i class="uil uil-list-ui-alt"></i>
                                        </v-select>
                                        <v-select class="profile-mobile-view-select" v-model="sortByTime" return-object item-text="value" item-value="key" outlined :items="[{key: 'all', value: 'بازه زمانی'},{key: 'day', value: 'روز گذشته'},{key: 'week', value: 'هفته گذشته'},{key: 'month', value: 'ماه گذشته'},{key: '3-month', value: '۳ ماه گذشته'},{key: '6-month', value: '۶ ماه گذشته'},{key: 'year', value: 'سال گذشته'}]" @input="selectFilter">
                                            <i class="uil uil-list-ui-alt"></i>
                                        </v-select>

                                    </div>
                                </div>
                                
                                <div class="technical-modal-box-row" >
                                    <slot v-if="technical_loading">
                                        <div class="tgju-widget-content full-padding">
                                            <div class="text-center p-2">
                                                <div class="widget-loading"></div>
                                            </div>
                                        </div>
                                    </slot>
                                    <div class="post-technical" v-for="(item, index) in technical_list" :key="index" v-if="technical_list">
                                        <div class="post-technical-img"
                                            :style="technicalImageFinder(item)">
                                        </div>
                                        <div class="post-technical-content">
                                            <h2>{{ item.title }}</h2>
                                            <p>{{ item.content }}</p>
                                            <div class="post-technical-content-row">
                                                <div class="post-technical-content-info" @click="saveChanges(item)">
                                                    <div class="tgju-btn tgju-btn-light-orange w-100">
                                                        <i class="uil uil-plus-circle"></i> 
                                                        <span>افزودن</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <infinite-loading @infinite="technicalInfiniteHandler" :key="componentReload">
                                        <div slot="spinner">
                                            <div class="widget-loading"></div>
                                        </div>
                                        <div slot="no-more"></div>
                                        <div slot="no-results"></div>
                                    </infinite-loading>
                                </div>
                                
                            </div>
                        </div>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>
    </div>
</template>

<script>
import { VEmojiPicker, emojisDefault, categoriesDefault } from "v-emoji-picker";
import $ from 'jquery';
import moment from "moment-jalaali";
import Editable from '@/components/Editable.vue';
import { Mentionable } from 'vue-mention';
import InfiniteLoading from "vue-infinite-loading";

Mentionable.methods.applyMention = function (itemIndex) {
    const item = this.displayedItems[itemIndex]
    const value = (this.omitKey ? '' : this.key) + String(this.mapInsert ? this.mapInsert(item, this.key) : item.value) + (this.insertSpace ? ' ' : '')
    if (this.input.isContentEditable) {
        const range = window.getSelection().getRangeAt(0)
        range.setStart(range.startContainer, range.startOffset - this.key.length - (this.lastSearchText ? this.lastSearchText.length : 0))
        range.deleteContents()
        const mention = document.createElement("span")
        const textNode = document.createTextNode(value)
        mention.appendChild(textNode)
        mention.innerHTML = `@${item.username}`
        range.insertNode(mention)
        range.setStart(range.endContainer, range.endOffset)
        this.emitInputEvent('input')
    } else {
        this.setValue(this.replaceText(this.getValue(), this.searchText, value, this.keyIndex))
        this.setCaretPosition(this.keyIndex + value.length)
    }
    this.$emit('apply', item, this.key, value)
    this.closeMenu()
}

export default {
    name: 'PostSendMobile',
    components: {
        VEmojiPicker,
        Editable,
        Mentionable,
        InfiniteLoading,
    },
    props: ['newPost'],
    data: function () {
        return {
            componentReload: Math.random(),
            selected: {key: 'newest', value: 'جدیدترین'},
            sortByTime: {key: 'all', value: 'بازه زمانی'},
            sendpost: false,
            sendPostLoading: false,
            timeout: null,
            searchUsername: '',
            searchMentionResult: false,
            technical_list : null,
            technical_image_preview : null,
            technical_attachment : null,
            technical_page: 2,
            technical_loading: true,
            showTechnicalsList: false,
            valueInput: "Someting text ",
            showEmojiDialog: false,
            mentionItems: [],
            users: [],
            filter:false,
            file_input_name: 'file_input_' + Math.random().toString(36).substring(7),
            customCategories : [
                {"name":"Frequently","icon":"\n    <svg style=\"max-height:18px\"\n      xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 219.15 219.15\" width=\"24\" height=\"24\" fill=\"gray\">\n      <path d=\"M109.575 0C49.156 0 .001 49.155.001 109.574c0 60.42 49.154 109.576 109.573 109.576 60.42 0 109.574-49.156 109.574-109.576C219.149 49.155 169.995 0 109.575 0zm0 204.15c-52.148 0-94.573-42.427-94.573-94.576C15.001 57.426 57.427 15 109.575 15c52.148 0 94.574 42.426 94.574 94.574 0 52.15-42.426 94.576-94.574 94.576z\"/>\n      <path d=\"M166.112 108.111h-52.051V51.249a7.5 7.5 0 0 0-15 0v64.362a7.5 7.5 0 0 0 7.5 7.5h59.551a7.5 7.5 0 0 0 0-15z\"/>\n    </svg>\n    "},
                {"name":"Peoples","icon":"\n    <svg style=\"max-height:18px\"\n      xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 106.059 106.059\" fill=\"gray\">\n      <path d=\"M90.544 90.542c20.687-20.684 20.685-54.341.002-75.024-20.688-20.689-54.347-20.689-75.031-.006-20.688 20.687-20.686 54.346.002 75.034 20.682 20.684 54.341 20.684 75.027-.004zM21.302 21.3c17.494-17.493 45.959-17.495 63.457.002 17.494 17.494 17.492 45.963-.002 63.455-17.494 17.494-45.96 17.496-63.455.003-17.498-17.498-17.496-45.966 0-63.46zM27 69.865s-2.958-11.438 6.705-8.874c0 0 17.144 9.295 38.651 0 9.662-2.563 6.705 8.874 6.705 8.874C73.539 86.824 53.03 85.444 53.03 85.444S32.521 86.824 27 69.865zm6.24-31.194a6.202 6.202 0 1 1 12.399.001 6.202 6.202 0 0 1-12.399-.001zm28.117 0a6.202 6.202 0 1 1 12.403.001 6.202 6.202 0 0 1-12.403-.001z\"/>\n    </svg>\n    "},
                {"name":"Nature","icon":"\n    <svg style=\"max-height:18px\"\n      xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\" width=\"24\" height=\"24\" fill=\"gray\">\n      <path d=\"M490.815 3.784C480.082 5.7 227.049 51.632 148.477 130.203c-39.153 39.153-64.259 87.884-70.694 137.218-5.881 45.081 4.347 85.929 28.878 116.708L.001 490.789 21.212 512l106.657-106.657c33.094 26.378 75.092 34.302 116.711 28.874 49.334-6.435 98.065-31.541 137.218-70.695C460.368 284.951 506.3 31.918 508.216 21.185L511.999 0l-21.184 3.784zm-43.303 39.493L309.407 181.383l-7.544-98.076c46.386-15.873 97.819-29.415 145.649-40.03zm-174.919 50.64l8.877 115.402-78.119 78.119-11.816-153.606c19.947-13.468 47.183-26.875 81.058-39.915zm-109.281 64.119l12.103 157.338-47.36 47.36c-39.246-52.892-24.821-139.885 35.257-204.698zm57.113 247.849c-26.548-.001-51.267-7.176-71.161-21.938l47.363-47.363 157.32 12.102c-40.432 37.475-89.488 57.201-133.522 57.199zm157.743-85.421l-153.605-11.816 78.118-78.118 115.403 8.877c-13.04 33.876-26.448 61.111-39.916 81.057zm50.526-110.326l-98.076-7.544L468.725 64.485c-10.589 47.717-24.147 99.232-40.031 145.653z\"/>\n    </svg>\n    "},
                {"name":"Symbols","icon":"\n    <svg style=\"max-height:18px\"\n      xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 511.626 511.626\" fill=\"gray\">\n      <path d=\"M475.366 71.949c-24.175-23.606-57.575-35.404-100.215-35.404-11.8 0-23.843 2.046-36.117 6.136-12.279 4.093-23.702 9.615-34.256 16.562-10.568 6.945-19.65 13.467-27.269 19.556a263.828 263.828 0 0 0-21.696 19.414 264.184 264.184 0 0 0-21.698-19.414c-7.616-6.089-16.702-12.607-27.268-19.556-10.564-6.95-21.985-12.468-34.261-16.562-12.275-4.089-24.316-6.136-36.116-6.136-42.637 0-76.039 11.801-100.211 35.404C12.087 95.55 0 128.286 0 170.16c0 12.753 2.24 25.891 6.711 39.398 4.471 13.514 9.566 25.031 15.275 34.546 5.708 9.514 12.181 18.792 19.414 27.834 7.233 9.041 12.519 15.272 15.846 18.698 3.33 3.426 5.948 5.903 7.851 7.427L243.25 469.938c3.427 3.426 7.614 5.144 12.562 5.144s9.138-1.718 12.563-5.144l177.87-171.31c43.588-43.58 65.38-86.406 65.38-128.472.001-41.877-12.085-74.61-36.259-98.207zm-53.961 199.846L255.813 431.391 89.938 271.507C54.344 235.922 36.55 202.133 36.55 170.156c0-15.415 2.046-29.026 6.136-40.824 4.093-11.8 9.327-21.177 15.703-28.124 6.377-6.949 14.132-12.607 23.268-16.988 9.141-4.377 18.086-7.328 26.84-8.85 8.754-1.52 18.079-2.281 27.978-2.281 9.896 0 20.557 2.424 31.977 7.279 11.418 4.853 21.934 10.944 31.545 18.271 9.613 7.332 17.845 14.183 24.7 20.557 6.851 6.38 12.559 12.229 17.128 17.559 3.424 4.189 8.091 6.283 13.989 6.283 5.9 0 10.562-2.094 13.99-6.283 4.568-5.33 10.28-11.182 17.131-17.559 6.852-6.374 15.085-13.222 24.694-20.557 9.613-7.327 20.129-13.418 31.553-18.271 11.416-4.854 22.08-7.279 31.977-7.279s19.219.761 27.977 2.281c8.757 1.521 17.702 4.473 26.84 8.85 9.137 4.38 16.892 10.042 23.267 16.988 6.376 6.947 11.612 16.324 15.705 28.124 4.086 11.798 6.132 25.409 6.132 40.824-.002 31.977-17.89 65.86-53.675 101.639z\"/>\n    </svg>\n    "}
            ],
            i18n : {
                search: 'جستجو...',
                categories: {
                    Activity: "Activity",
                    Flags: "Flags",
                    Foods: "Foods",
                    Frequently: "Frequently",
                    Objects: "Objects",
                    Nature: "Nature",
                    Peoples: "Peoples",
                    Symbols: "Symbols",
                    Places: "Places",
                }
            }
        }
    },
    computed: {
        _newPost: {
            get() {
                return this.$parent.newPost;
            },
            set(value) {
                this.$parent.newPost = value;
            }
        },
        type() {
            return this.newPost.type;
        },
        // گذاشتن تاخیر هنگام جستجو نام کاربری در قسمت منشن‌ها
        _searchUsernameDelay: {
            get() {
            return this.searchUsername
            },
            set(val) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.searchUsername = val
            }, 250)
            }
        },
        checkUserAvatar: function () {
            if (this.$store.state.user) {
                if (this.$store.state.user.avatar) {
                    return this.$helpers.api_url() + this.$store.state.user.avatar.name;
                }
            } 

            return 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';
        }
    },
    watch: {
        newPost: {
            handler: function (newValue, oldValue) {
                if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                    //
                }
            },
            immediate: true
        },
        // در صورت تغییر جستجو منشن دوباره اطلاعات از سرور بگیرد
        searchUsername: {
            handler: function (newValue, oldValue) {
                let api_data = {
                    username: newValue.trim(),
                };

                this.searchMentionResult = true;
                this.$helpers.makeRequest('GET', '/user/search-mention', api_data).then(api_response => {
                    if (api_response.status == 200) {
                        this.mentionItems = api_response.data;
                    }
                    this.searchMentionResult = false;
                });
            }
        }
    },
    mounted() {
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        selectFilter() {
            this.loadTechnicalList();
            this.componentReload = Math.random();
        },
        technicalInfiniteHandler($state) {
            this.$helpers
                .makeRequest("GET", `/technical/all-list?page=${ this.technical_page }&filter=${ this.selected.key }&time=${ this.sortByTime.key }&market=all&bookmark=0`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.technicals.last_page >= this.technical_page) {
                            this.technical_page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.technicals.data)) {
                                this.technical_list.push(value);
                            }
                        $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        // به کلاس پرنت نیز کال بک می کند
        saveChanges(item) {
            let technical = {
                'technical_id' : item.technical.post_id,
            }
            this.technical_image_preview = item;
            this.technical_attachment = technical;
            this.showTechnicalsList = false;
        },
        removeTechnical() {
            this.technical_image_preview = null;
            this.technical_attachment = null;
        },
        technicalImageFinder(item) {
            return  {
                'position' : 'relative',
                'background-image': 'url('+ this.$helpers.api_url() + this.$helpers.json_pars(item.image)[0].name + ')',
            }
        },
        loadTechnicalList() {
            this.technical_page = 2;
            this.$helpers
                .makeRequest("GET", `/technical/all-list?page=1&filter=${ this.selected.key }&time=${ this.sortByTime.key }&market=all&bookmark=0`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.technical_list = api_response.data.response.technicals.data;
                        this.technical_loading = false;
                    }
                });
        },
        // مسئول تاگل کردن قسمت نمایش ایموجی
        toggleTechnicalsList() {
            if(!this.showTechnicalsList) {
                this.technical_page = 2;
                this.loadTechnicalList();
            } else {
                this.technical_loading = true;
            }
            this.showTechnicalsList = !this.showTechnicalsList;
        },
        // نمایش مودال ارسال پست
        showSendPost(){
            $('.re-comments-box').addClass('show-popup-sendpost');
        },
        closeSendPost(){
            $('.re-comments-box').removeClass('show-popup-sendpost');
            var self = this;
            setTimeout(function() {
                self.sendpost = false; 
            }, 3000);
            
        },
        // اپدیت کردن وضعیت تایپ در قسمت منشن
        updateMention(payload) {
            this._searchUsernameDelay = payload;
        },
        // مسئول تاگل کردن قسمت نمایش ایموجی
        toggleEmoji() {
            this.showEmojiDialog = !this.showEmojiDialog;
        },
        // درج ایموجی در هر جای متن
        onSelectEmoji(emoji) {
            var element = this.$refs['textarea-post'].$el;
            var doc = element.ownerDocument || element.document;
            var win = doc.defaultView || doc.parentWindow;
            var sel;

            if (typeof win.getSelection != "undefined" && window.getSelection().containsNode(element, true)) {
                sel = win.getSelection();
                if (sel.rangeCount > 0) {
                    var range = win.getSelection().getRangeAt(0);
                    var preCaretRange = range.cloneRange();
                    preCaretRange.selectNodeContents(element);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    var caretPosition = preCaretRange.endOffset;
                    var currentElement = $(preCaretRange.endContainer)[0];

                    if (caretPosition == 0) {
                        if (currentElement !== element) {
                            currentElement.textContent = emoji.data + currentElement.textContent;
                        } else {
                            currentElement.innerHTML = emoji.data + currentElement.innerHTML;
                        }
                    } else {
                        currentElement.textContent = currentElement.textContent.slice(0, caretPosition) + emoji.data + currentElement.textContent.slice(caretPosition);
                    }

                    // بلاک پایین موقعیت قبلی caret  رو فیکس می کنه
                    var setpos = document.createRange();
                    setpos.setStart(currentElement, caretPosition);
                    setpos.collapse(true);
                    sel.removeAllRanges();
                    sel.addRange(setpos);
                    element.focus();
                }
            } else {
                this.$refs['textarea-post'].$el.innerHTML += emoji.data;
            }
            this._newPost.content = this.$refs['textarea-post'].$el.innerHTML;
        },
        // این متد جهت هندل کردن آپلود فایل می باشد
        uploadAttachment(fileList) {
            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment
                };

                if (this.newPost.attachments.length) {
                    this.removeAttachment(this._newPost.attachments[0].id);
                }

                this.$helpers.makeRequest('POST', '/post/upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;

                        this.newPost.attachments.push(response);
                    }
                });
            }
        },
        // این متد جهت حذف فایل می باشد
        removeAttachment(id) {
            let index = this.$helpers.getIndex(this._newPost.attachments, id);
            let attachment = this._newPost.attachments[index];

            let api_data = {
                image: attachment.name.split('/').reverse()[0]
            };

            this.$helpers.makeRequest('DELETE', '/post/remove-attachment/', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this._newPost.attachments.splice(index, 1);
                }
            });
        },
        parseNewPostContent(e) {
            // this._newPost.content = this.parseEmoji(e.target.innerHTML);
            // setTimeout(() => {
            // 	this.setCaretToEnd(this.$refs['textarea-post']);
            // }, 1);
        },
        setCaretToEnd(target /*: HTMLDivElement*/ ) {
            const range = document.createRange();
            const sel = window.getSelection();
            // range.selectNodeContents(target);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
            // target.focus();
            range.detach(); // optimization
            // set scroll to the end if multiline
            target.scrollTop = target.scrollHeight;
        },
        // این متد جهت اسکرول به باکس های متنی می باشد
        scrollTo(container) {
            this.$scrollTo(this.$refs['textarea-post'], 500, {
                container,
                offset: (container == 'body') ? -130 : -20
            });
        },
        // این متد وظیفه ارسال درخواست ذخیره سازی نظر جدید را دارد
        sendPost() {
            if (!this._newPost.content.trim()) {
                return;
            }

            if (this._newPost.id) {
                return this.editPost();
            }

            if (this.technical_attachment) {
                this.newPost.attachments.push(this.technical_attachment);
            }

            let api_data = {
                content: this._newPost.content.replace(/(<\/?(?:span|div)[^>]*>)|<[^>]+>/ig, '$1'),
                type: this._newPost.type,
                parent_id: this._newPost.parent_id,
                subject: this._newPost.subject,
                attachments: JSON.stringify(this._newPost.attachments),
            };

            if (this.type == 'tgju-comment') {
                api_data.post_id = this.$parent.post.id;
            } 

            this.sendPostLoading = true;
            this.$helpers.makeRequest('POST', '/tgju-post/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    // this.$toast.success({
                    //     title: 'پست ها',
                    //     message: `${this.type == 'tgju-post' ? 'پست' : 'نظر'} جدید شما با موفقیت ثبت شد`
                    // });
                    let post = api_response.data.response.post;

                    if (this.type == 'tgju-post') {
                        // TODO: خط زیر باید کامنت بشه تا همه چیز درست کار کنه Issues #31
                        // if ( this._newPost.parent_id ) {
                        // 	post.parent = this._newPost.parent;
                        // 	this.$parent.closeModal();
                        // }
                        $("<div class='input-alert input-alert-success'>پست شما با موفقیت ارسال شد</div>").appendTo('.mentionable').delay(3000).slideUp('fast').queue(function() { $(this).remove(); });
                        
                        // this.$swal.fire({
                        //     icon: "success",
                        //     title: "پست شما با موفقیت ارسال شد",
                        //     showConfirmButton: !1,
                        //     timer: 4000
                        // });
                        // this.$parent.posts.data.unshift(post);
                    } else {
                        if (this._newPost.parent.type == 'tgju-comment') {
                            // this._newPost.parent.comments.unshift(post);
                            // this.$swal.fire({
                            //     icon: "success",
                            //     title: "نظر شما با موفقیت ارسال شد و در انتظار تایید می باشد",
                            //     showConfirmButton: !1,
                            //     timer: 4000
                            // });
                        $("<div class='input-alert input-alert-success'>نظر شما با موفقیت ارسال شد</div>").appendTo('.mentionable').delay(3000).slideUp('fast').queue(function() { $(this).remove(); });

                        } else {
                            this.$parent.commentsRefreshEvent = Math.random();
                        }

                        this.$parent.post.comments_count += 1;
                    }
                    this.resetNewPost();
                    this.sendPostLoading = false;
                    this.closeSendPost();
                } else if (api_response.status == 400) {
                    // this.$swal.fire({
                    //     icon: "error",
                    //     title: "گروه ارسالی فاقد اعتبار می باشد",
                    //     showConfirmButton: !1,
                    //     timer: 4000
                    // });
                    $("<div class='input-alert input-alert-danger'>گروه ارسالی فاقد اعتبار می باشد</div>").appendTo('.mentionable').delay(3000).slideUp('fast').queue(function() { $(this).remove(); });
                    this.sendPostLoading = false;
                } else if (api_response.status == 451) {
                    let notification_content = "<div class='input-alert input-alert-danger' style='height: auto;text-indent: 0px;padding: 10px;line-height: 1.5;text-align: justify;'>" + api_response.data.result.notification_content + "</div>";
                    $(notification_content).appendTo('.mentionable').delay(3000).slideUp('fast').queue(function() { $(this).remove(); });
                    this.sendPostLoading = false;
                }
            });
        },
        // این متد وظیفه ویرایش نظر را دارد
        editPost() {
            let post = []
            if (this._newPost.type == 'tgju-comment') {
                post = this._newPost;
            } else {
                let index = this.$helpers.getIndex(this.$parent.posts.data, this._newPost.id);
                post = this.$parent.posts.data[index];
            }

            let api_data = {
                content: this._newPost.content,
                attachments: JSON.stringify(this._newPost.attachments),
            };

            this.sendPostLoading = true;
            this.$helpers.makeRequest('POST', '/tgju-post/edit/' + post.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                post.content = this._newPost.content;
                post.attachments = this._newPost.attachments;

                if (api_response.status == 200) {
                    // this.$toast.success({
                    //     title: 'پست ها',
                    //     message: 'پست شما با موفقیت ویرایش شد'
                    // });
                    
                    $("<div class='input-alert input-alert-success'>پست شما با موفقیت ویرایش شد</div>").appendTo('.mentionable').delay(3000).slideUp('fast').queue(function() { $(this).remove(); });

                    if (this._newPost.type == 'tgju-comment') {
                        this.$parent.commentsRefreshEvent = Math.random();
                    }

                    if (post.attachments.length) {
                        post.content += `<br><img class="post-img" src="${this.$helpers.api_url() + post.attachments[0].name}">`;
                    }
                    this.resetNewPost();
                }
                this.sendPostLoading = false;

            });
        },
        // این متد وظیفه خالی کردن باکس نظر را دارد (ریست کردن یا منصرف شدن)
        resetNewPost() {
            this.sendpost = false;
            this._newPost.id = null;
            this._newPost.attachments = [];
            this._newPost.comments = [];
            this._newPost.content = '';
            this._newPost.created_at = null;
            this._newPost.liked = false;
            this._newPost.likes = 0;
            this._newPost.parent_id = 0;
            this._newPost.reshares = 0;
            this._newPost.saved = false;
            this._newPost.user = null;
            this._newPost.user_id = null;

            if (this.newPost.type == 'tgju-comment') {
                let parent = this.$helpers.getParent(this);
                parent.resetReply();
            }
        },
        mentionOnOpen(key) {
            if (key === '@') {
                this.mentionItems = this.users;
            }
        },
    },
}
</script>

<style>

.mention-str {
    color: #00ABE7 !important;
    background-color: rgba(147, 147, 147, 0.07) !important;
    padding: 0 7px;
    border-radius: 4px;
}

.container-emoji {
    font-family: iranyekan !important;
    height: 238px !important;
}

#Categories {
    overflow-x: visible !important;
}
.emoji-picker {
    position: absolute;
    z-index: 99999999999999999999;
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker {
    top: 40px;
    left: 0;
    padding: 0;
}
.emoji-picker__search {
    display: flex;
}
.emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}
.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}
.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}
.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}
.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
</style>